import React, { useState, useEffect } from 'react';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from './firebase';

const InfiniteScrollPhotos = () => {
  const [photos, setPhotos] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [enlargedPhoto, setEnlargedPhoto] = useState(null);
  const totalPhotos = 43;
  const photosPerPage = 10;

  useEffect(() => {
    const fetchPhotos = async () => {
      setLoading(true);
      try {
        const start = (page - 1) * photosPerPage;
        const newPhotos = [];
        for (let i = 0; i < photosPerPage; i++) {
          const photoId = (start + i) % totalPhotos + 1;
          const photoRef = ref(storage, `PaytonRoper${photoId}.jpg`);
          const photoUrl = await getDownloadURL(photoRef);
          newPhotos.push({ id: photoId, url: photoUrl });
        }
        setPhotos(prevPhotos => [...prevPhotos, ...newPhotos]);
      } catch (error) {
        console.error('Error fetching photos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPhotos();
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.scrollHeight - 5 &&
        !loading
      ) {
        setPage(prevPage => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading]);

  const handlePhotoClick = (photo) => {
    setEnlargedPhoto(enlargedPhoto === photo.id ? null : photo.id);
  };

  return (
    <div className={`infinite-scroll-container ${enlargedPhoto ? 'fullscreen' : ''}`}>
      <div className="photos-list">
        {photos.map(photo => (
          <div
            key={photo.id}
            className={`photo-item ${enlargedPhoto === photo.id ? 'enlarged' : ''}`}
            onClick={() => handlePhotoClick(photo)}
          >
            <img src={photo.url} alt={`Photo ${photo.id}`} className="photo" />
          </div>
        ))}
        {loading && (
          <div className="loading-indicator">
            &#10085;&nbsp;Payton Roper
          </div>
        )}
      </div>
      {enlargedPhoto && (
        <div className="fullscreen-overlay" onClick={() => setEnlargedPhoto(null)}>
          <img
            src={photos.find(photo => photo.id === enlargedPhoto)?.url}
            alt={`Photo ${enlargedPhoto}`}
            className="fullscreen-photo"
          />
        </div>
      )}
    </div>
  );
};

export default InfiniteScrollPhotos;

