// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAAgLvjnRHIGaPbCBOCwdlnSbwUL3AO3to",
  authDomain: "ilostmyipad.firebaseapp.com",
  databaseURL: "https://ilostmyipad-default-rtdb.firebaseio.com",
  projectId: "ilostmyipad",
  storageBucket: "ilostmyipad.appspot.com",
  messagingSenderId: "1096571522428",
  appId: "1:1096571522428:web:2b2711712df8a6d46928f9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the storage service
const storage = getStorage(app);

export { storage };

